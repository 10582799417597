
html { scroll-behavior: smooth; } 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}




.selected {
  background-color: #9ae79d;

}

.accept_button {
  background-color: #05820a;
  color: white;

}

.cancel_button {
  background-color: #e50b0b;
  color: white;

 
}

.inputcontainer {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  padding: 10px;


}

.selectable_option {
  margin-right: 5px;
}


.inputTextEditable{
  padding-right: 5px;
}


.report_section_header {
  background-color: #5456ca;
  padding: 20px;
}


.DraftEditor-editorContainer {
  border-radius: 5px;
  border: 1px solid #5456ca;
  padding: 10px;
}

.single_line .DraftEditor-editorContainer {
  height: 70px;
}

.multi_line .DraftEditor-editorContainer {
  height: 300px;
}

.public-DraftEditor-content {
  height: 90%;
  overflow: scroll;
}

.auth_navbar {
  background-color: #5456ca;
  padding: 10px;
  display: flex;
  align-items: center;
  color: white;
}

.auth_navbar_item {
  margin-right: 10px;
  color: rgb(205, 205, 205);
}


.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% ,-50%);
  width: 400;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 10px;
}
 
.MuiDataGrid-row {
  cursor: pointer;
}




/* animations */


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.option {
  display: inline-block;
  margin: 0 4px;
  padding: 2px 6px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f3f3f3;
  cursor: pointer;
}

.option.selected {
  background-color: #c8e6c9;
  border-color: #4caf50;
}


@keyframes pulse {
  0% {
      transform: scale(0.9);
  }

  50% {
      transform: scale(1.1);
  }

  100% {
      transform: scale(0.9);
  }
}

.animate-charcter
{
  background-image: linear-gradient(
    -225deg,
    #b4b4b4 0%,
    #7d7d7d 29%,
    #fa709e 57%,
    #b4b4b4 87%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 14px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.bg-gradient {
  background: rgb(9,70,124);
  background: radial-gradient(circle, rgba(9,70,124,1) 0%, rgba(3,21,37,1) 100%);
}

/* 
.css-j3jqhj-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 20px!important;
} */

.ql-container  {
  border-radius: 5px!important;
  border-top-right-radius: 0!important;
  border-top-left-radius: 0!important;
  border-color: #7d7d7d!important;
}

.ql-toolbar {
  border-radius: 5px!important;
  border-bottom-right-radius: 0!important;
  border-bottom-left-radius: 0!important;
  border-color: #7d7d7d!important;
}

