@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

h5 {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 1rem;
}


/* Style for Unordered Lists */
ul {
  list-style-type: disc; /* Default bullet style */
  margin: 16px 0; /* Adds spacing above and below the list */
  padding-left: 40px; /* Ensures bullets align properly */
  font-size: 16px; /* Adjust font size */
}

/* Style for List Items */
li {
  margin-bottom: 2px; /* Adds spacing between list items */
  line-height: 1.6; /* Improves readability */
}

/* Optional: Nested Lists */
ul ul {
  list-style-type: circle; /* Changes bullet style for nested lists */
  margin-top: 6px;
  padding-left: 20px;
}

/* Optional: Custom Styling */
ul.custom-list {
  list-style-type: none; /* Removes default bullets */
}

ul.custom-list li::before {
  content: "✔"; /* Custom checkmark bullet */
  color: green;
  font-weight: bold;
  margin-right: 10px;
}


